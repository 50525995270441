.header-logo {
  cursor: pointer;
  margin-top: 15px;
  padding: 15px;
  animation: blinkAnimation 3s infinite;
  flex: 1;  /* New */
}

.header-menu {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .header-logo{
    margin-left: 17px;
  }

  .header-menu {
    display: block;
    justify-content: space-between;
  }
}

/* @keyframes blinkAnimation {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
} */


.menu-button {
  cursor: pointer;
  color: #8c9ffe;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

.menu-button:focus {
  outline: none;
}
.menu-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
}

.menu-items li {
  margin-right: 1rem;
}

.menu-items li:last-child {
  margin-right: 250px;
}

.menu-items a {
  text-decoration: none;
  color: #8c9ffe;
}

.menu-items a:hover {
  color: #5566c7;
  font-weight: 600;
  text-decoration: none !important;
}

@media screen and (max-width: 1200px) {
  .menu-button {
    left: 1000px;
  }
}

@media screen and (max-width: 992px) {
  .menu-button {
    left: 800px;
  }
}

@media screen and (max-width: 768px) {
  .menu-button {
    left: 600px;
  }
}