  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    font-family: 'Chakra Petch';
    border: 2px solid #aa6ffe;
    table-layout: fixed;
  }

  table th{
    padding: 7px !important;
    opacity: 1;
    text-align: center;
    padding-bottom: 10px;
    font-size: 24px;
    background: linear-gradient(to bottom, #aa6ffe 0%, #8c9ffe 50%, #82e3fc 100%);
    border: 1px solid #aa6ffe;
  }

  table thead tr {
    border-spacing: 0 30px;
  }

  table tbody{
    color: white;
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 768px) {
    table tbody {
      font-size: 16px;
    }
  
    table th {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    table tbody {
      font-size: 16px;
      display: inline-block;
      overflow: auto;
      width: 334px;
      scrollbar-color: lightblue purple;
    }
  
    table th {
      font-size: 20px;
    }
  }