.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #110e18;
}

.main-content {
    flex: 1;
    width: 100%;
}
